<template>
	<div
		v-if="!program.not_side"
		class="side bg-base"
	>
		<div
			class="side-container flex-column"
		>
			<ul
				class="side-content overflow-y pb-50"
			>
				<template
					v-for="item in items"
				>
					<li
						v-if="!item.not_view &&item.type.indexOf(user.role) > -1"
						:key="item.path"
						class="cursor-pointer size-px-14"
						:class="'li-side-depth-' + item.depth"
					>
						<div
							class="pa-10 justify-space-between"
						>
							<span
								class="font-weight-bold flex-column justify-center ptb-5"
							>{{ item.name }}</span>
						</div>
						<ul>
							<template
								v-for="(sub, index) in item.sub"
							>
								<li
									v-if="!sub.not_view && sub.type.indexOf(user.role) > -1"
									:key="item.path + sub.path + '_' + index"
									class=" bg-white under-line"
									:class="{'on-li': isOn(item, sub)}"
									@click="toPush(item, sub)"
								>
									<div
										class="justify-space-between gap-10"
									>
										<div class="bar"></div>
										<div class="justify-space-between pa-10 subject flex-1">
											{{ sub.name }}
										</div>
									</div>
								</li>
							</template>
						</ul>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Side'
		,props: ['Axios', 'user', 'program']
		,data: function(){
			return {
				items: [
					{
						path: 'Admin'
						,name: '관리자 '
						, type: ['admin']
						, sub: [
							{
								path: 'List'
								,name: '관리자 목록'
								,type: ['admin']
							}
						]
					}
					, {
						path: 'Dashboard'
						,name: '대시보드 '
						, type: ['admin', 'distributor', 'agency']
						, sub: [
							{
								path: ''
								,name: '대시보드'
								,type: ['admin', 'distributor', 'agency']
							}
						]
					}
					, {
						path: 'Agency'
						,name: '영업점 '
						, type: ['admin', 'distributor']
						, sub: [
							{
								path: 'List'
								,name: '영업점 목록'
								,type: ['admin', 'distributor']
							}
						]
					}
					,{
						path: 'Supply'
						, name: '공급사 '
						, type: ['admin']
						, not_view: true
						, sub: [
							{
								path: 'List'
								,name: '공급사 목록'
								,type: ['admin', 'distributor']
							}
							,{
								path: 'Item'
								,name: '공급사 등록'
								,type: ['admin', 'distributor']
								,not_view: true
							}
							,{
								path: 'Detail'
								,name: '공급사 상세정보'
								,type: ['admin', 'distributor']
								,not_view: true
							}
						]
					}
					,{
						path: 'Member'
						,name: '회원관리'
						,type: ['admin', 'distributor', 'agency']
						,sub: [
							{
								path: 'List'
								,name: '회원목록'
								,type: ['admin', 'distributor', 'agency']
							}
							, {
								path: 'Point'
								,name: '회원 포인트 내역'
								,type: ['admin', 'distributor', 'agency']
							}
							, {
								path: 'OperatorList'
								,name: '영업자 목록'
								,type: ['admin', 'distributor']
								, not_view: this.user.sales_type == 1 ? false : true
							}
						]
					}
					,{
						path: 'Product'
						,name: '상품 관리'
						,type: ['admin', 'distributor', 'agency', 'supply']
						,sub: [
							{
								path: 'List'
								,name: '상품권 목록'
								,type: ['admin', 'distributor', 'agency', 'supply']
								, params: {
									pdt_div: 'ticket'
								}
							}
							,{
								path: 'List'
								,name: '일반 상품 목록'
								,type: ['admin', 'distributor', 'agency', 'supply']
							}
							,{
								path: 'List'
								,name: '기업 상품 목록'
								,type: ['admin', 'distributor', 'agency', 'supply']
								, params: {
									pdt_div: 'business'
								}
							}
							,{
								path: 'Sales'
								,name: '매입 상품 목록'
								,type: ['admin', 'distributor', 'agency', 'supply']
								, not_view: this.user.sales_type == 1 ? false : true
							}
						]
					}
					,{
						path: 'Point'
						,name: '포인트 관리'
						,type: ['manager', 'admin', 'agency']
						,sub: [
							{
								path: 'Items'
								,name: '포인트 상품'
								,type: ['manager', 'admin', 'agency']
							}
							,{
								path: 'History'
								,name: '포인트 결제 목록'
								,type: ['manager', 'admin', 'agency']
							}
						]
					}
					,{
						path: 'Order'
						,name: '주문 관리'
						,type: ['admin', 'distributor', 'agency', 'supply']
						,sub: [
							{
								path: 'List'
								,name: '상품권 주문 목록'
								,type: ['admin', 'distributor', 'agency']
								, params: {
									pdt_div: 'ticket'
								}
							}
							, {
								path: 'List'
								,name: '일반 상품 주문 목록'
								,type: ['admin', 'distributor', 'agency']
							}
							, {
								path: 'List'
								,name: '기업 상품 주문 목록'
								,type: ['admin', 'distributor', 'agency']
								, params: {
									pdt_div: 'business'
								}
							}

							,{
								path: 'List'
								,name: '주문 목록'
								,type: ['supply']
							}
						]
					}
					,{
						path: 'Settlement'
						,name: '정산 관리'
						,type: ['admin', 'distributor', 'agency', 'supply']
						, not_view: true
						,sub: [
							{
								path: 'Management'
								,name: '정산 실행'
								,type: ['manager']
							}
							,{
								path: 'List'
								,name: '정산 내역'
								,type: ['admin', 'distributor', 'agency', 'supply']
							}
							,{
								path: 'Agency'
								,name: '대리점 정산'
								,type: []
							}
							,{
								path: 'Agency'
								,name: '정산내역'
								,type: ['']
							}
							,{
								path: 'Supply'
								,name: '공급사 정산'
								,type: []
							}
							,{
								path: 'Supply'
								,name: '정산내역'
								,type: ['']
							}
							,{
								path: 'DeliveryList'
								,name: '배송비 내역'
								,type: ['admin', 'supply']
							}
							,{
								path: 'Minus'
								,name: '정산 차감'
								,type: []
							}
						]
					}
					,{
						path: 'Banner'
						,name: '배너 관리'
						,type: ['admin', 'agency']
						,sub: [
							{
								path: 'List'
								,name: '배너 목록'
								,type: ['admin', 'agency']
							}
						]
					}
					,{
						path: 'Popup'
						,name: '팝업 관리'
						,type: ['admin']
						,sub: [
							{
								path: 'List'
								,name: '팝업 목록'
								,type: ['admin']
							}
						]
					}
					,{
						path: 'CustomerCenter'
						,name: '고객센터'
						,type: ['admin', 'distributor', 'agency', 'supply']
						,sub: [
							{
								path: 'NoticeList'
								,name: '공지사항'
								,type: ['admin', 'agency']
							}
							,{
								path: 'FaqList'
								,name: 'FAQ'
								,type: ['admin', 'distributor', 'agency', 'supply']
							}
							,{
								path: 'QnAList'
								,name: '1:1문의'
								,type: ['admin', 'distributor', 'agency', 'supply']
							}
							,{
								path: 'ReviewList'
								,name: '상품 리뷰'
								,type: ['admin', 'distributor', 'agency', 'supply']
							}
						]
					}
					,{
						path: 'Pg'
						, name: 'PG 관리'
						, type: ['admin']
						, sub: [
							{
								path: 'List'
								, name: 'PG 목록'
								, type: ['admin']
							}
							, {
								path: 'NotiList'
								, name: 'PG 노티 목록'
								, type: ['admin']
							}
						]
					}
					,{
						path: 'Statistics'
						, name: '통계 관리'
						, type: ['admin', 'distributor', 'agency']
						, sub: [
							{
								path: 'List'
								, name: '월별 통계'
								, type: ['admin', 'distributor', 'agency']
							}
						]
					}
					,{
						path: 'Manager'
						, name: '설정'
						, type: ['manager']
						, sub: [
							{
								path: 'List'
								, name: '관리자 목록'
								, type: ['manager']
							}
							,{
								path: 'Setting'
								, name: '관리 설정'
								, type: ['admin']
							}
						]
					}
					,{
						path: 'Mypage'
						, name: '설정'
						, type: ['admin']
						, sub: [
							{
								path: 'Agency'
								, name: '관리 설정'
								, type: ['admin']
							}
						]
					}
					,{
						path: 'Mypage'
						, name: '설정'
						, type: ['supply']
						, sub: [
							{
								path: 'Supply'
								, name: '상점 설정'
								, type: ['supply']
							}
						]
					}
					,{
						path: 'Mypage'
						, name: '설정'
						, type: ['distributor', 'agency', ]
						, sub: [
							{
								path: 'Agency'
								, name: '상점 설정'
								, type: ['distributor', 'agency', ]
							}
						]
					}
				]
				,item_new: {

				}
			}
		}
		,computed: {
			item_list: function(){
				return this.items.filter(function(item){

					if(item.type.indexOf())

					return item.sub.filter(function(sub){

						return sub
					})
				})
			}
		}
		,methods: {
			toPush: function(menu, sub){
				console.log('toPush : ' +menu.path + sub.path)
				this.$storage.push({ name: menu.path + sub.path, params: sub.params})
			}
			, isOn: function(item, sub){
				let t = false
				t = this.$route.name ? this.$route.name.indexOf(item.path + sub.path) > -1 : false
				if(t){
					switch (this.$route.name){
						case 'ProductCategory':
							if(this.$route.params.type == sub.params?.type){
								t = true
							}else{
								t = false
							}
							break;
						case 'ProductList':
							if(this.$route.params.pdt_div == sub.params?.pdt_div){
								t = true
							}else{
								t = false
							}
							break;
						case 'OrderList':
							if(this.$route.params.pdt_div == sub.params?.pdt_div){
								t = true
							}else{
								t = false
							}
							break;
					}

				}

				return  t
			}
		}
		,created: function(){
			console.log('in side !!' + this.$route.name)
			console.log(this.$route.path)
		}
	}
</script>

<style>


</style>
