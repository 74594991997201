

export const language = {
	common: {
		askRemove: '삭제하시겠습니까?'
		, error_file_limit: '첨부파일은 최대 10개입니다'
		, success: "정상적으로 처리되었습니다"
	}

	,img_url: 'https://delimall.co.kr/NEW_API_DIV/data/product/'
}